$main:  #2A9D8F;
$dark: #264653;
$accent: #E9C46A;
$content-margin: 20px;

.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site-content {
  flex: 1;
}

main > article {
  margin: $content-margin;

  p {
    margin: 10px 0;
  }
}

.sidebar {
  margin: $content-margin;
}

.recaptcha_disclaimer {
  font-size: 10px;
}

.image {
  padding-top: 10px;
  padding-bottom: 10px;
}

.photos, .articles, .reviews {
  h1 {
    font-size: 2.5em;
  }

  h2 {
    font-weight: 600;
    color: #425469;
    font-size: 2em;
  }

  time {
    color: #898989;
    font-size: 1em;
  }

  a {
    color: #425469;

    h3 {
      display: inline;
      font-size: 1em;
    }
  }
}

.home {
  .title {
    margin-bottom: 0;
  }

  time {
    color: #898989;
    font-size: 1em;
  }
}

.post {
  margin-bottom: 1em;
}

.tag_page {
  h2 {
    font-weight: 600;
    color: #425469;
    font-size: 2em;
    margin-bottom: 20px;
  }
}